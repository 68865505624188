import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Contact from "./Pages/Contact/Contact";

const Landing = lazy(() => import("./Pages/Landing/Landing"));
const About = lazy(() => import("./Pages/About/About"));
const BookDemo = lazy(() => import("./Pages/BookDemo/BookDemo"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy/PrivacyPolicy"));
const Terms = lazy(() => import("./Pages/Terms/Terms"));
const CustomerSupport = lazy(() =>
  import("./Pages/CustomerSupport/CustomerSupport")
);

const App = () => {
  return (
    <>
      <Suspense>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/book-demo" element={<BookDemo />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/customer-support" element={<CustomerSupport />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
