import React from "react";
import "./Footer.scss";
import { Link, useNavigate } from "react-router-dom";

const Footer = ({ title, description }) => {
  const navigate = useNavigate();
  return (
    <div className="footer_wrapper">
      <div className="container">
        <div className="incredible_together">
          <h1 className="max-w-[729px] mb-[18px]">{title}</h1>
          <p className="max-w-[581px] mb-[42px]">{description}</p>
          <button
            onClick={() => navigate("/book-demo")}
            className="book_demo_btn flex items-center gap-2"
          >
            <span>Book a Demo</span>
            <img src={require("../../assets/img/RightIcon.png")} alt="" />
          </button>
        </div>
        <div className="flex md:flex-row flex-col gap-4 justify-between md:items-center md:mb-[56px] mb-[32px]">
          <img
            className="w-fit"
            src={require("../../assets/img/Trovex_white.png")}
            alt="logo"
          />
          <div className="flex md:flex-row flex-col md:gap-[32px] gap-4">
            <Link className="nav_link" to="/about">
              About
            </Link>
            {/* <a className="nav_link" href="https://trovex.ai/blog">
              Blog
            </a> */}
            {/* <Link className="nav_link" to="/careers">
              Careers
            </Link> */}
            <Link className="nav_link" to="/contact">
              Contact Us
            </Link>
            <Link className="nav_link" to="/privacy-policy">
              Privacy Policy
            </Link>
            <Link className="nav_link" to="/terms">
              Terms of service
            </Link>
          </div>
        </div>
        <div className="all_right_reserved">
          <p className="copy_right md:order-1 order-2">
            ©{new Date().getFullYear()} Cloudsprint Technologies Pvt Ltd. All
            Rights Reserved.
          </p>
          {/* <div className="flex gap-[24px] md:order-2 order-1">
            <img
              className="cursor-pointer"
              src={require("../../assets/img/instagram.png")}
              alt=""
            />
            <img
              className="cursor-pointer"
              src={require("../../assets/img/linkedin.png")}
              alt=""
            />
            <img
              className="cursor-pointer"
              src={require("../../assets/img/twitter.png")}
              alt=""
            />
            <img
              className="cursor-pointer"
              src={require("../../assets/img/facebook.png")}
              alt=""
            />
            <img
              className="cursor-pointer"
              src={require("../../assets/img/github.png")}
              alt=""
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
