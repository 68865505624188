import React, { useEffect, useState } from "react";
import "./Contact.scss";
import Header from "../../Common/Header/Header";
import Footer from "../../Common/Footer/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Fade, Zoom } from "react-reveal";
import axios from "axios";
import { toast } from "react-toastify";

const Contact = () => {
  const [fields, setFields] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [error, setError] = useState({
    status: false,
    type: "",
    message: "",
  });

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      if (value.length >= 1) {
        setFields({
          ...fields,
          [name]: value,
        });
        setError({
          ...error,
          status: false,
          type: name,
          message: "",
        });
      } else {
        setFields({
          ...fields,
          [name]: value,
        });
      }
    }
    if (name === "email") {
      if (emailRegex.test(value)) {
        setError({
          ...error,
          status: false,
          type: name,
          message: "",
        });
        setFields({
          ...fields,
          [name]: value,
        });
      } else {
        setFields({
          ...fields,
          [name]: value,
        });
      }
    }

    if (name === "message") {
      if (value.length >= 1) {
        setFields({
          ...fields,
          [name]: value,
        });
        setError({
          ...error,
          status: false,
          type: name,
          message: "",
        });
      } else {
        setFields({
          ...fields,
          [name]: value,
        });
      }
    }
  };

  const onChangePhoneNo = (e) => {
    if (e.length >= 1) {
      setFields({
        ...fields,
        mobile: e,
      });
      setError({
        ...error,
        status: false,
        type: "mobile",
        message: "",
      });
    } else {
      setFields({
        ...fields,
        mobile: e,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (fields.name.length < 1) {
      setError({
        ...error,
        status: true,
        type: "name",
        message: "Name is required",
      });
    } else if (!emailRegex.test(fields.email)) {
      setError({
        ...error,
        status: true,
        type: "email",
        message: "Email is required",
      });
    } else if (fields.mobile.length < 1) {
      setError({
        ...error,
        status: true,
        type: "mobile",
        message: "Mobile no is required",
      });
    } else if (fields.message.length < 1) {
      setError({
        ...error,
        status: true,
        type: "message",
        message: "Message is required",
      });
    } else {
      const bodyData = {
        name: fields.name,
        email: fields.email,
        contact_no: fields.mobile,
        message: fields.message,
      };
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/contact-us`, bodyData)
        .then((response) => {
          setFields({
            name: "",
            email: "",
            mobile: "",
            message: "",
          });
          toast.success("Email send successfully");
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="contact_wrapper">
        <div className="contact_title_wrapper">
          <div className="container">
            <h1 className="mb-[12px]">Contact Us</h1>
            <p className="pb-[56px]">
              Fill up the form and our team will get back to you within 24
              hours.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] mt-[-141px] md:mb-[80px] mb-[56px]">
            <div className="form_wrapper lg:p-[40px] md:p-[20px] md:order-1 order-2">
              <form onSubmit={handleSubmit}>
                <div className="mb-[20px]">
                  <label htmlFor="name" className="mb-[8px]">
                    Name
                  </label>
                  <input
                    placeholder="Enter your name"
                    type="text"
                    id="name"
                    name="name"
                    value={fields.name}
                    onChange={onChange}
                  />
                  {error.status && error.type === "name" && (
                    <div
                      className={
                        fields.name.length < 1
                          ? "error_div_red"
                          : "error_div_gray"
                      }
                    >
                      <div>{error.message}</div>
                    </div>
                  )}
                </div>
                <div className="mb-[20px]">
                  <label htmlFor="email" className="mb-[8px]">
                    Email
                  </label>
                  <input
                    placeholder="Enter email"
                    type="text"
                    id="email"
                    name="email"
                    value={fields.email}
                    onChange={onChange}
                  />
                  {error.status && error.type === "email" && (
                    <div
                      className={
                        fields.email.length < 1
                          ? "error_div_red"
                          : "error_div_gray"
                      }
                    >
                      <div>{error.message}</div>
                    </div>
                  )}
                </div>
                <div className="mb-[20px]">
                  <label htmlFor="mobile" className="mb-[8px]">
                    Mobile
                  </label>
                  <PhoneInput
                    placeholder="Enter your mobile number"
                    country={"in"}
                    // enableSearch={true}
                    value={fields.mobile}
                    onChange={onChangePhoneNo}
                  />
                  {error.status && error.type === "mobile" && (
                    <div
                      className={
                        fields.mobile.length < 1
                          ? "error_div_red"
                          : "error_div_gray"
                      }
                    >
                      <div>{error.message}</div>
                    </div>
                  )}
                </div>
                <div className="mb-[20px]">
                  <label htmlFor="message" className="mb-[8px]">
                    How can we help you? *
                  </label>
                  <textarea
                    placeholder="Type a message here..."
                    type="text"
                    id="message"
                    name="message"
                    value={fields.message}
                    onChange={onChange}
                  />
                  {error.status && error.type === "message" && (
                    <div
                      className={
                        fields.message.length < 1
                          ? "error_div_red"
                          : "error_div_gray"
                      }
                    >
                      <div>{error.message}</div>
                    </div>
                  )}
                </div>
                <button onClick={handleSubmit}>Submit</button>
              </form>
            </div>
            <Zoom>
              <img
                className="mx-auto md:mt-[-90px] md:order-2 order-1"
                src={require("../../assets/img/contactUS.webp")}
                alt=""
              />
            </Zoom>
          </div>
          <div className="love_hear_from">
            <h2 className="mb-[12px]">Love to hear from you !</h2>
            <h4 className="mb-[46px] md:mb-[56px]">
              Feel free to contact us for queries 👋
            </h4>
            <Fade top>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-[32px] mb-[56px] md:mb-[80px]">
                <div className="contact_card">
                  <img
                    className="mx-auto mb-[20px]"
                    src={require("../../assets/img/email.png")}
                    alt=""
                  />
                  <h1 className="mb-[12px]">Email</h1>
                  <p>hello@trovex.ai</p>
                </div>
                <div className="contact_card">
                  <img
                    className="mx-auto mb-[20px]"
                    src={require("../../assets/img/call.png")}
                    alt=""
                  />
                  <h1 className="mb-[12px]">Call</h1>
                  <p>+919986016910</p>
                </div>

                <div className="contact_card">
                  <img
                    className="mx-auto mb-[20px]"
                    src={require("../../assets/img/address.png")}
                    alt=""
                  />
                  <h1 className="mb-[12px]">Address</h1>
                  <p>Bhive workplaces, BTM Layout, Bangalore-560078</p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <Footer
        title="Experience the power of AI-driven sales training"
        description="Ready to revolutionize your sales team’s performance?"
      />
    </>
  );
};

export default Contact;
