import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.scss";

const Header = () => {
  const [toggle, setToggle] = useState(false);
  const [isActiveSub, setIsActiveSub] = useState(false);
  const ref = useRef();
  const navigate = useNavigate();

  const showNav = () => {
    setToggle(!toggle);
  };

  const navbarData = [
    {
      title: "Solution",
      href: null,
      subHref: [
        {
          title: "Sales",
          href: "/",
        },
        {
          title: "Customer Support",
          href: "/customer-support",
        },
      ],
    },
    {
      title: "About",
      href: "/about",
    },
  ];

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsActiveSub(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveSub]);

  return (
    <>
      <div className="header_wrapper">
        <div className="public_header">
          <nav className="w-full items-center flex container">
            <div className="flex justify-between items-center w-full flex-wrap md:flex-nowrap py-[12px] gap-3">
              <Link to="/">
                <img
                  src={require("../../assets/img/Trovex_white.png")}
                  alt="logo"
                />
              </Link>
              <button className="flex justify-end md:hidden" onClick={showNav}>
                <img
                  src={require("../../assets/img/HambburgerMenu.png")}
                  alt="logo"
                />
              </button>

              <ul
                className={`${
                  toggle ? " flex" : " hidden"
                } flex-col justify-center items-center w-full md:flex-row md:w-auto md:flex gap-3 md:gap-[32px]`}
              >
                {navbarData.map((d, index) => {
                  return (
                    <li key={index}>
                      <div className="relative list_container">
                        <Link
                          className="nav_link flex gap-3"
                          to={d.href}
                          onClick={() =>
                            d.subHref ? setIsActiveSub(!isActiveSub) : showNav()
                          }
                        >
                          {d.title}
                          {d.subHref && (
                            <img
                              className={isActiveSub ? "rotate-180" : ""}
                              src={require("../../assets/img/down_arrow.png")}
                              alt="logo"
                            />
                          )}
                        </Link>
                        {d.subHref && (
                          <ul
                            ref={ref}
                            className={`more_button_list ${
                              isActiveSub ? "active" : ""
                            }`}
                          >
                            {d.subHref.map((subData, subIndex) => (
                              <li
                                key={subIndex}
                                className="more_button_list_item"
                                onClick={() => {
                                  setIsActiveSub(!isActiveSub);
                                  navigate(subData.href);
                                }}
                              >
                                <Link to={subData.href}>{subData.title}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </li>
                  );
                })}
                <button
                  onClick={() => navigate("/book-demo")}
                  className={`${
                    toggle ? " flex" : " hidden"
                  } mx-auto md:ml-16 md:flex items-center justify-center book_demo_btn `}
                >
                  Book a Demo
                </button>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
