import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ToastContainer
      limit={3}
      autoClose={1200}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      draggable
      pauseOnHover
      pauseOnFocusLoss={false}
    />
    <App />
  </BrowserRouter>
);

reportWebVitals();
